<template>
    <v-card class="mt-10">
            <v-card-title >
               <h3 class="zamu-heading admin-title admin-section-title"> Blog Categories</h3>
            <v-spacer></v-spacer>
            
            </v-card-title>
            <v-card-text>
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="admin-search"
                outlined
                dense
            ></v-text-field>
            </v-card-text>
            <div v-if="categories === null">
                <h3>No blog categories found</h3>

            </div>
                <v-data-table
                :headers="headers"
                :items="categoryData"
                :search="search"

                sort-by="calories"
                class="elevation-1 users-table"
                v-else
            >
    <template v-slot:top>
      <v-toolbar flat class="text-right mt-14" width="100%">
        <v-spacer></v-spacer>
        <v-btn :color="`${zamuPurple2}`" href="/admin/new-category" dark class="mb-2 mr-4">
                New Category
            </v-btn>
       
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
     <template v-slot:item.status="{ item }">
        <span class="" :class="item.status === 1 ? 'active' : 'deactivated'"> {{ item.status === 1 ? 'active' : 'deactivated' }}</span>
      </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
   <v-pagination v-if="categories !== null" v-model="currentPage"  class="zamu-pagination mt-5" :length="pagination.lastPage" :total-visible="7" ></v-pagination>

        </v-card>
</template>

<script>

import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';


export default {
    name: 'BlogCategories',
     components: {
    },
    mixins: [coreMixin, colorMixin],

    data() {
        return {
            categories: null,
            search: '',
            dialog: false,
            dialogDelete: false,
             currentPage: 1,
            deletedID: null,

        headers: [
          {
            text: 'Category',
            align: 'start',
            sortable: false,
            value: 'category',
          },
          { text: 'Description', value: 'description' },
          { text: 'Status', value: 'status' },
          { text: 'Date Created ', value: 'created_at' },
          { text: 'Date Updated', value: 'updated_at' },
          { text: 'Actions', value: 'actions', sortable: false }
        ],
        editedIndex: -1,
        };
    },
     computed: {
       categoryData () {
            return this.categories !== null ? this.categories.data : null;
        },
        pagination () {
            let data = null;
            if (this.categories !== null) {
                 data = {
                    total: this.categories.total,
                    perPage: this.categories.perPage,
                    page: this.categories.page,
                    lastPage: this.categories.lastPage,
                };
            }
            return data;

        },
    },
      watch: {
        async currentPage(page) {
             this.categories = await this.retrieveBlogCategories(page);

        },
    },

    async mounted() {
        this.categories = await this.retrieveBlogCategories(this.currentPage);
        
    },
    methods: {

        editItem (item) {
            return this.$router.push({ name: 'single-blog-categories', params: { id: item.id } });
         
        },


      deleteItem (item) {
        this.deletedID = item.id;
        this.dialogDelete = true
      },

     async deleteItemConfirm () {

              const payload = {
                    status: 0,
                };
                const fullPayload = {
                    app: this.apiUrl,
                    params: payload,
                    endpoint: `blog-categories/deactivate/${this.deletedID}`,
                };
                  try {
                    const res = await this.axiosPutRequest(fullPayload);
                    this.loading = false;
                    this.notification = res.data ? 'Blog Category deactivated successful' : 'Blog Category failed to deactivate';
                    this.actionClass = res.data  ? 'success' : 'error';
                    if(res.data) {
                      this.retrieveBlogCategories(1)
                      location.reload();

                    }
                  

                } catch (error) {
                    this.notification = 'Blog Category failed to deactivate. Contact the admin';
                    this.actionClass = 'error';
                    this.loading = false;
                    return error.response;
                }

        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

    }

}
</script>

<style>

</style>